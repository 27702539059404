import React from 'react'
import SvgIcon from 'components/SvgIcon'

const IconLogo = props => (
  <SvgIcon x="0px" y="0px" {...props} viewBox="219 329 177 62" height="1.7em" width="11.4em">
    <g id="Layer_1">
      <path
        className="st0"
        style={{ fill: '#0088B2' }}
        d="M215.35,389.22l-4.69-12.68h-18.77l-4.95,12.68H170.7l23.72-57.66h13.45l23.72,57.66H215.35z M201.27,347.29
    		l-6.34,18.44h11.92L201.27,347.29z"
      />
      <path
        className="st0"
        style={{ fill: '#0088B2' }}
        d="M269.9,389.22l-13.19-23.02h-3.17v23.02h-15.09v-57.66h26.38c10.78,0,19.92,3.99,19.92,17.26
    		c0,9.63-4.95,14.68-12.3,16.21l14.71,24.19H269.9z M261.02,341.89h-7.48v14.33h7.48c5.84,0,8.24-3.52,8.24-7.16
    		C269.27,345.3,267.11,341.89,261.02,341.89z"
      />
      <path
        className="st0"
        style={{ fill: '#0088B2' }}
        d="M341.41,386.17c-4.44,2.82-9.13,4.23-18.52,4.23c-9.51,0-14.59-1.41-19.15-4.23
    		c-6.47-3.88-8.75-8.34-8.75-19.61v-35h15.1v35.58c0,7.16,5.07,11.98,13.32,11.98c8.63,0,12.43-5.99,12.43-11.98v-35.58h14.71v35
    		C350.54,376.89,348,382.17,341.41,386.17z"
      />
      <path
        className="st0"
        style={{ fill: '#0088B2' }}
        d="M404.77,389.34l-27.15-37.34v37.23h-13.95v-57.66h16.24l25.88,37.58v-37.58h13.95v57.78H404.77z"
      />
      <path
        className="st0"
        style={{ fill: '#0088B2' }}
        d="M472.68,389.22l-4.69-12.68h-18.77l-4.95,12.68h-16.24l23.72-57.66h13.45l23.72,57.66H472.68z M458.6,347.29
    		l-6.34,18.44h11.92L458.6,347.29z"
      />
      <path
        className="st1"
        style={{ fill: '#44C5E2' }}
        d="M177.21,331.2h-19.5c0,0-7.65,16.99-14.17,31.65c4.4,9.53,8.3,18.07,9.17,20.3c0.79,2.01,0.53,3.76-0.3,5.21
    		c0.64-0.84,1.24-1.83,1.81-2.99C157.65,378.31,177.21,331.2,177.21,331.2z"
      />
      <path
        className="st2"
        style={{ opacity: '0.15', fill: '#FFFFFF' }}
        d="M177.21,331.2h-19.5c0,0-7.65,16.99-14.17,31.65c4.4,9.53,8.3,18.07,9.17,20.3c0.79,2.01,0.53,3.76-0.3,5.21
    		c0.64-0.84,1.24-1.83,1.81-2.99C157.65,378.31,177.21,331.2,177.21,331.2z"
      />
      <path
        className="st0"
        style={{ fill: '#0088B2' }}
        d="M134.65,383.15c0.87-2.23,4.64-10.77,8.89-20.3c-6.76-14.65-14.72-31.65-14.72-31.65h-19.5
    		c0,0,20.39,47.11,23.82,54.17c0.56,1.16,1.17,2.15,1.81,2.99C134.12,386.91,133.86,385.16,134.65,383.15z"
      />
      <path
        className="st3"
        style={{ fill: '#00A6D3' }}
        d="M152.71,383.15c-0.87-2.23-4.77-10.77-9.17-20.3c-4.24,9.53-8.01,18.07-8.89,20.3
    		c-0.79,2.01-0.53,3.77,0.3,5.21c2.71,3.59,5.96,4.54,8.73,4.56c2.77-0.02,6.02-0.96,8.73-4.56
    		C153.23,386.91,153.5,385.16,152.71,383.15z"
      />
      <g>
        <path
          className="st0"
          style={{ fill: '#0088B2' }}
          d="M495.24,383.73v5.53h-1.26v-5.53H492v-1.07h5.22v1.07H495.24z"
        />
        <path
          className="st0"
          style={{ fill: '#0088B2' }}
          d="M503.92,389.25v-4.84c-0.05,0.34-0.04,0.39-0.25,0.94l-1.51,3.9h-1.13l-1.38-3.83
    			c-0.14-0.38-0.15-0.49-0.25-1.05v4.88h-1.14v-6.6h1.51l1.76,4.71c0.07,0.19,0.1,0.26,0.13,0.51c0.04-0.31,0.06-0.35,0.12-0.51
    			l1.83-4.71h1.51v6.6H503.92z"
        />
      </g>
    </g>
    <g id="Layer_2"></g>
  </SvgIcon>
)

export default IconLogo
