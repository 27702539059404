import React, { useState, useCallback } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import Typography from 'components/Typography'
import IconData from 'icons/IconData'
import IconHouse from 'icons/IconHouse'
import IconQuestion from 'icons/IconQuestion'
import IconBell from 'icons/IconBell'

import './MobileNavMenu.scss'

function NavMenuItem({ path, icon: Icon, text, subMenuItems, selfNav }) {
  const [expand, setExpand] = useState(false)

  const handleMenuClick = useCallback(
    e => {
      if (!selfNav) {
        e.preventDefault()
      }
      setExpand(expand => !expand)
    },
    [selfNav]
  )

  return (
    <div className="MobileNavMenu__tab">
      {subMenuItems && <input type="checkbox" id={text} checked={expand} className="d-none" readOnly />}

      <Link to={path} onClick={handleMenuClick}>
        <div className="MobileNavMenu__tab-highlight">
          <Icon className="MobileNavMenu__icon" />
          <div className="MobileNavMenu__text">
            <Typography variant="subtitle">{text}</Typography>
          </div>
        </div>
      </Link>
    </div>
  )
}

function MobileNavMenu({ className, userRole }) {
  return (
    <Nav className={cn('MobileNavMenu', className)}>
      <NavMenuItem text="OVERVIEW" path="/overview" icon={IconHouse} />
      <NavMenuItem text="PUMP REPORT" path="/pump-reports" icon={IconData} />
      <NavMenuItem text="SETTING" path="/setting" icon={IconBell} />
      <NavMenuItem text="HELP" path="/help" icon={IconQuestion} />
    </Nav>
  )
}

export default MobileNavMenu
