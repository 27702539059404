import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import MainLayout from 'components/MainLayout'
import Scenario from 'routes/Overview/routes/Overview/components/Scenario'
import Chart from 'routes/Overview/routes/Overview/components/Chart'
import OverviewMap from 'routes/Overview/routes/Overview/components/OverviewMap'
import Insights from 'routes/Overview/routes/Overview/components/Insights'
import Weather from 'routes/Overview/routes/Overview/components/Weather'
import './SensorStatus.scss'

const SensorStatus = () => {
  return (
    <MainLayout>      
      <div className="SensorStatus">
        <Row className="row-spacer">
          <Col className="col-spacer" xs={12} xl={4}>
            <Row className="row-spacer">
              <Col className="col-spacer" xs={12}>
                <Card className="SensorStatus__map">
                  <Card.Body>
                    <Scenario/>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="row-spacer mt-3">
              <Col className="col-spacer" xs={12}>
                <Card className="SensorStatus__map">
                  <Card.Body>
                    <OverviewMap/>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xl={8}>
            <Col className="col-spacer chart-col">
              <Card>
                <Card.Body>
                  <Chart/>
                </Card.Body>
              </Card>
            </Col>
            <Row className="row-spacer mt-2">
              <Col className="col-spacer" lg={6}>
                <Card>
                  <Card.Body>
                    <Weather/>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-spacer" lg={6}>
                <Card>
                  <Card.Body>
                    <Insights/>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </MainLayout>
  )
}

export default SensorStatus
