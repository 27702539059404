import React from 'react'
import { DatePicker, Tooltip } from 'antd'
import { SettingFilled } from '@ant-design/icons'
import moment from 'moment'
import './Insights.scss'

const Insights = () => {
  const insightSection = `This section shares the 
      recommendations and correlations that 
      Varuna’s tool has generated from our 
      analytics/algorithms.`
  return (
    <div className="pump-container pt-0">
      <div className="insights">
        <div className="py-2">
          <Tooltip title={insightSection}>
            <span className="title me-4 mr-4">INSIGHTS</span>
          </Tooltip>
          <DatePicker defaultValue={moment('01/01/2015', 'DD/MM/YYYY')} format={'DD/MM/YYYY'} />
        </div>
        <div className="content-div">
          <div className="d-flex align-items-center">
            <SettingFilled style={{ color: '#74a242' }} />
            <span className="ms-1" style={{ color: '#74a242' }}>
              MAINTAINANCE:{' '}
            </span>
            <span> &nbsp;Pump 505164-1</span>
          </div>
          <div>
            Routine Monitoring: Investigating the cause of increase in pressure
            <div>(1) Inspect pressure valves in the proximity of Vaura unit</div>
            <div>
              (2) ensure that this abnormality is center in this location and the rest of the system is funtioning as
              expected
            </div>
            <div>(3) confirm that the pressure at the treatment plain has not changed</div>
            <div>(4) Inspect pressure valves in the proximity of Vaura unit</div>
            <div>
              (5) ensure that this abnormality is center in this location and the rest of the system is funtioning as
              expected
            </div>
            <div>(6) confirm that the pressure at the treatment plain has not changed</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Insights
