import React from 'react'
import cn from 'classnames'
import Dropdown from 'react-bootstrap/Dropdown'
import IconProfile from 'icons/IconProfile'

import './FooterUserActions.scss'

function FooterUserActions(props) {
  const { className, logout } = props

  return (
    <div className={cn('FooterUserActions', className)}>
      <Dropdown drop="up">
        <Dropdown.Toggle as="div" className="FooterUserActions__arrow">
          <IconProfile className="FooterUserActions__icon" />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight={true}>
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default FooterUserActions
