import React from 'react'

import './HeaderNav.scss'
import IconProfile from 'icons/IconProfile'

const HeaderNav = () => (
  <div className="HeaderNav d-none d-sm-block">
    <span className='ml-4'>Leo Mei</span>
    <IconProfile className='ml-2 profile' />
  </div>
)

export default HeaderNav
