import React from 'react'
import { Checkbox, Col, Row, Tooltip } from 'antd'
import Button from 'react-bootstrap/Button'
import SemiCircleProgressBar from 'react-progressbar-semicircle'
import { Line } from 'react-chartjs-2'
import './Chart.scss'

const data = {
  labels: ['0', '100', '200', '300', '400', '500', '600'],
  datasets: [
    {
      label: 'PUMP 505164-1',
      data: [0, 20, 25, 30, 40, 50, 60],
      fill: false,
      borderColor: '#74a242'
    },
    {
      label: 'PUMP 505164-2',
      data: [10, 20, 35, 43, 44, 55, 50],
      fill: false,
      borderColor: '#f5a335'
    },
    {
      label: 'PUMP 505164-3',
      data: [5, 10, 20, 40, 50, 50, 30],
      fill: false,
      borderColor: '#c73c5c'
    },
    {
      label: 'PUMP 505164-4',
      data: [60, 50, 45, 40, 35, 10, 0],
      fill: false,
      borderColor: '#da693e'
    },
    {
      label: 'PUMP 505164-5',
      data: [50, 45, 45, 40, 20, 15, 10],
      fill: false,
      borderColor: '#009396'
    }
  ]
}

const legend = {
  display: true,
  position: 'bottom',
  labels: {
    fontColor: '#323130',
    fontSize: 14
  }
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 60
        }
      }
    ]
  }
}

const chartHoverText = `The curves show the 
    performance of the pumps based on 
    the efficiency, head performance or 
    Min Absolute Pressure (selected 
    underneath the chart)`

const Chart = () => {
  const handleRunReportClick = () => {
    window.print()
  }
  return (
    <div id="ChartToPrint" className="curve">
      <Tooltip title={chartHoverText}>
        <span className="title">CURVES</span>
      </Tooltip>
      <Row className="mt-2">
        <Col className="text-center" xs={{ span: 24 }}>
          <Line height={120} data={data} legend={legend} options={options} />
        </Col>
        <p className="mgd-caption">Flow(MGD)</p>
      </Row>

      <Row className="mt-3">
        <Col className="d-flex flex-column" xs={{ span: 12 }} lg={{ span: 12 }}>
          <Checkbox style={{ marginLeft: '8px' }} className="round">
            Head Performance
          </Checkbox>
          <Checkbox className="round">Effieciency</Checkbox>
          <Checkbox className="round" disabled="true">
            Power Consumption
          </Checkbox>
          <Checkbox className="round">Minimum Absolute Pressure</Checkbox>
        </Col>
        <Col
          xs={{ span: 12 }}
          lg={{ span: 12 }}
          className="d-flex"
          style={{ marginLeft: 'auto', alignItems: 'self-start' }}>
          <div className="d-flex">
            <div className="col-4 d-flex align-center justify-center flex-column left-curve">
              <SemiCircleProgressBar
                percentage={75}
                showPercentValue
                stroke={'#f4a335'}
                strokeWidth={10}
                diameter={100}
              />
              <span>EFFICIENCY</span>
              <span className="round-progressbar-caption">PUMP 505164-2</span>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-4 d-flex align-center justify-center flex-column right-curve">
              <SemiCircleProgressBar
                percentage={75}
                showPercentValue
                stroke={'#009396'}
                strokeWidth={10}
                diameter={100}
              />
              <span>EFFICIENCY</span>
              <span className="round-progressbar-caption">PUMP 505164-2</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="btn_print">
        <Col>
          <Button onClick={handleRunReportClick}>PRINT PDF</Button>
        </Col>
      </Row>
    </div>
  )
}

export default Chart
