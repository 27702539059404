import React from 'react'
import SvgIcon from 'components/SvgIcon'

const IconHouse = props => (
  <SvgIcon x="0px" y="0px" viewBox="0 2 32.25 32" width="1.07em" height="1.07em" {...props}>
    <path
      fill="currentColor"
      d="M 1.624,17.782L 4,15.882L 4,30 c0,1.104, 0.896,2, 2,2l 6,0 c 1.104,0, 2-0.896, 2-2l0-8 l 4,0 l0,8 c0,1.104, 0.896,2, 2,2l 6,0 c 1.104,0, 2-0.896, 2-2L 28,15.882 l 2.376,1.9C 30.56,17.928, 30.78,18, 31,18c 0.294,0, 0.584-0.128, 0.782-0.376 c 0.344-0.432, 0.274-1.060-0.156-1.406l-15-12c-0.366-0.292-0.884-0.292-1.25,0L 10,8.518L 10,6 c0-1.104-0.896-2-2-2S 6,4.896, 6,6l0,5.718 l-5.624,4.5c-0.43,0.346-0.5,0.974-0.156,1.406C 0.564,18.056, 1.192,18.126, 1.624,17.782z M 16,6.28l 10,8L 26,30 l-6,0 l0-10 L 12,20 l0,10 L 6,30 L 6,14.282 L 16,6.28z "
    />
  </SvgIcon>
)

export default IconHouse
