import { Select, DatePicker, Table, Col, Row, Input, Tooltip } from 'antd'
import Button from 'react-bootstrap/Button'
import moment from 'moment'
import SemiCircleProgressBar from 'react-progressbar-semicircle'
import './Scenario.scss'
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons'
import React from 'react'

const { Option } = Select
const dateFormat = 'MM/DD/YY'

const Scenario = () => {
  const handleRunReportClick = () => {}
  const dataSource = [
    {
      key: '1',
      Parameter: 'Energy Usage',
      Number: 3.5,
      Unit: 'kW',
      Trend: 1
    },
    {
      key: '2',
      Parameter: 'Flow',
      Number: 320,
      Unit: 'MGD',
      Trend: 1
    },
    {
      key: '3',
      Parameter: 'Pressure',
      Number: 120,
      Unit: 'psi',
      Trend: 0
    },
    {
      key: '4',
      Parameter: 'Total Dynamic Head',
      Number: 120,
      Unit: 'ft',
      Trend: 1
    },
    {
      key: '5',
      Parameter: 'Max Power',
      Number: 120,
      Unit: 'hp',
      Trend: 1
    }
  ]

  const columns = [
    {
      title: 'Parameter',
      dataIndex: 'Parameter',
      key: 'Parameter'
    },
    {
      title: 'Number',
      dataIndex: 'Number',
      key: 'Number',
      render: text => <span style={{ fontWeight: 'bold', color: 'black' }}>{text}</span>
    },
    {
      title: 'Unit',
      dataIndex: 'Unit',
      key: 'Unit'
    },
    {
      title: 'Trend',
      dataIndex: 'Trend',
      key: 'Trend',
      render: bool =>
        bool ? (
          <div className="caret-up">
            <CaretUpFilled /> <span className="val">{Math.floor(Math.random() * 7 + 1)} </span>
          </div>
        ) : (
          <div className="caret-down">
            <CaretDownFilled />
            <span className="val">{Math.floor(Math.random() * 7) + 1} </span>
          </div>
        )
    }
  ]
  const rltSource = [
    {
      key: '1',
      Results: 'Shutoff Head',
      Number: '110ft'
    },
    {
      key: '2',
      Results: 'Shutoff Differential Pressure',
      Number: '132psi'
    },
    {
      key: '3',
      Results: 'Best Efficienty Point(BEP)',
      Number: '84% at 300 MGD'
    },
    {
      key: '4',
      Results: 'Max Curve: Max Power',
      Number: '78.3hp at 310MGD'
    }
  ]
  const rltColumns = [
    {
      title: 'RESULTS',
      dataIndex: 'Results',
      key: 'Results'
    },
    {
      title: '',
      dataIndex: 'Number',
      key: 'Number',
      render: text => <span style={{ fontWeight: 'bold', color: 'black' }}>{text}</span>
    }
  ]
  const parameterSection = `
            This section of the Pump 
            Dashboard enables the 
            user to select a pump in 
            a certain location and 
            see the parameters 
            (Energy, flow, pressure, 
            TD Head and Max 
            Power) related to the 
            pump selected.`
  const runScenarioSection = `This section of the Pump Dashboard enables the user to run possible scenarios to 
            determine the optimal operation range for the pump selected. User can select Flow, Pressure and Water levels scenarios and see results of the Shutfoff Head and 
            Differential Pressure, BEP and Max Power for the scenario. These results are also 
            reflected in the curves/efficiency chart section.`

  return (
    <div className="scenario">
      <Tooltip title={parameterSection}>
        <span className="title me-4 mr-4">PUMPS</span>
      </Tooltip>
      <div className="d-flex mt-2">
        <div className="col-8 left-accerator">
          <div className="select-input mb-2">
            <Select defaultValue="1">
              <Option value="1">PUMP505164-1</Option>
              <Option value="2">PUMP505164-2</Option>
              <Option value="3">PUMP505164-3</Option>
              <Option value="4">PUMP505164-4</Option>
              <Option value="5">PUMP505164-5</Option>
            </Select>
          </div>
          <DatePicker defaultValue={moment('01/24/22', dateFormat)} format={dateFormat} />
        </div>
        <div className="col-4 d-flex align-center justify-center flex-column right-accerator">
          <SemiCircleProgressBar percentage={75} showPercentValue stroke={'#f4a335'} strokeWidth={10} diameter={100} />
          <span>EFFICIENCY</span>
        </div>
      </div>
      <Table className="col-12 my-4" dataSource={dataSource} columns={columns} pagination={false} />
      <Tooltip title={runScenarioSection}>
        <span className="title mt-4">RUN SCENARIO</span>
      </Tooltip>
      <Row className="mt-4">
        <Col className="scenario-item">
          <div className="d-flex justify-between">
            <Input className="col-4" placeholder={'Min Flow'} />
            <Input className="col-4" placeholder={'Pressure'} />
            <Input className="col-4" placeholder={'Water level'} />
          </div>

          <Button onClick={handleRunReportClick} className="justify-content-center mb-4">
            RUN
          </Button>
        </Col>
      </Row>
      <Table className="col-12 mt-4" dataSource={rltSource} columns={rltColumns} pagination={false} />
    </div>
  )
}

export default Scenario
