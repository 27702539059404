import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import FormInput from 'components/FormInput'
import Typography from 'components/Typography'
import IconLogo from 'icons/IconLogo'

import './Login.scss'

const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required')
})

const signupValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required')
})

const loginInit = {
  username: '',
  password: '',
}

const signupInit = {
  email: '',
}

const LoginForm = ({ handleSubmit }) => (
  <form className="LoginForm" onSubmit={handleSubmit}>
    <Field name="username" type="input" placeholder="Username" component={FormInput} autoFocus />
    <Field name="password" type="password" placeholder="Password" component={FormInput} />

    <div className="d-flex justify-content-between">
      <Field name="rememberme" type="checkbox" label="Remember me" component={FormInput} />      
      <Button variant="primary" type="submit" className="ml-3">
        <Typography uppercase as="h6">
          Login
        </Typography>
      </Button>
    </div>
    <Link to="/forgot-password">
      <Typography className="text-right">
        <small>Forgot your password?</small>
      </Typography>
    </Link>
    <div className='d-flex justify-content-between align-items-center my-3'>
      <div className='line-div'></div>
      <span className='d-flex font-weight-bold mx-2 or-divider'>or</span>
      <div className='line-div'></div>
    </div>
  </form>
)

const SignUpForm = ({ handleSubmit }) => (
  <form className="SignUpForm" onSubmit={handleSubmit}>
    <Field name="email" type="email" placeholder="Enter Email Address" component={FormInput} />
    <Button variant="primary" style={{float: 'right'}}>
      <Typography uppercase as="h6">
        SIGN UP
      </Typography>
    </Button>
  </form>
)

function Login() {

  let navigate = useHistory();

  const handleLoginSubmit = (values) => {
    navigate.push("/overview");
  }

  const handleSignUpSubmit = () => {
  }

  return (
    <div className="Login">
      <Card className="shadow Login__card">
        <Card.Body className="p-0 d-flex flex-column justify-content-center">
          <IconLogo className="Login__logo" />
          <Formik
            component={LoginForm}
            onSubmit={handleLoginSubmit}
            validationSchema={loginValidationSchema}
            initialValues={loginInit}
          />
          <Formik
            component={SignUpForm}
            onSubmit={handleSignUpSubmit}
            validationSchema={signupValidationSchema}
            initialValues={signupInit}
          />
        </Card.Body>
      </Card>
    </div>
  )
}

export default Login
