import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import ReactWeather, { useOpenWeather } from 'react-open-weather'
import { OPEN_WEATHER_KEY } from 'config/constants'
import { Tooltip } from 'antd'
import './Weather.scss'

const Weather = () => {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: OPEN_WEATHER_KEY,
    lat: '39.440334',
    lon: '-84.362160',
    lang: 'en',
    unit: 'metric' // values are (metric, standard, imperial)
  })
  const weatherSection = `This section contains weather 
    information for the city/locale where the 
    data being viewed is associated with.`
  return (
    <div className="pump-container pt-0">
      <div className="weather">
        <div className="py-2">
          <Tooltip title={weatherSection}>
            <span className="title me-4 mr-4">LOCAL TEMPERATURE</span>
          </Tooltip>
          <DatePicker defaultValue={moment('01/01/2015', 'DD/MM/YYYY')} format={'DD/MM/YYYY'} />
        </div>
        <div>
          <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="en"
            locationLabel="MONROE"
            unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
            showForecast={false}
          />
        </div>
      </div>
    </div>
  )
}

export default Weather
