import React from 'react'
import SvgIcon from 'components/SvgIcon'

const IconBell = props => (
  <SvgIcon x="0px" y="0px" viewBox="0 0 32 33.76" {...props}>
    <path
      fill="currentColor"
      d="M27.89 25.65c-.56-.49-1.76-2.04-2.32-3.38-.56-1.34-.77-4.86-.77-6.34 0-1.48.21-2.32-.4-4.32-.94-3.09-3.2-4.18-4.46-4.9-1.15-.66-.51-1.62-.56-2.67-.07-1.41-1.6-2.67-3.21-2.67-1.06 0-2.37.89-2.79 1.67s-.19 1.85-.19 2.63-1.06 1.25-2.3 1.99c-1.89 1.12-3.2 3.98-3.2 6.1 0 1.62-.35 5.7-.7 7.67-.35 1.97-1.69 3.38-2.04 3.73-.35.35-.99.63-.99 1.55s.99 1.06.99 1.06h22.24c.56 0 .63-.07.92-.35.27-.29.34-1.28-.22-1.77zM16.18 5.8a1.16 1.16 0 1 1 1.16-1.16c.01.64-.51 1.16-1.16 1.16zM16.42 32.34c2.96 0 3.87-3.31 3.87-3.31h-8.31s1.48 3.31 4.44 3.31z"
    />
  </SvgIcon>
)

export default IconBell
