import React from 'react'
import SvgIcon from 'components/SvgIcon'

const IconQuestion = props => (
  <SvgIcon x="0px" y="0px" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M 16,32c 8.836,0, 16-7.164, 16-16s-7.164-16-16-16S0,7.164,0,16S 7.164,32, 16,32z M 16,4c 3.982,0, 8,2.28, 8,7.376 c0,2.966-1.47,5.282-4.368,6.876C 18.792,18.714, 18,19.628, 18,20c0,1.104-0.896,2-2,2s-2-0.896-2-2c0-2.538, 2.328-4.496, 3.704-5.252 C 19.726,13.632, 20,12.416, 20,11.376C 20,8.438, 17.494,8, 16,8C 14.074,8, 12,9.264, 12,12.042c0,1.104-0.896,2-2,2s-2-0.896-2-2 C 8,6.818, 12.122,4, 16,4z M 16,28.088c-1.138,0-2.060-0.922-2.060-2.060s 0.922-2.060, 2.060-2.060s 2.060,0.922, 2.060,2.060S 17.138,28.088, 16,28.088z "
    />
  </SvgIcon>
)

export default IconQuestion
