import React from 'react'
import cn from 'classnames'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAP_KEY } from 'config/constants'
import './UtilityMap.scss'

const UtilityMap = ({ markers, className, onMarkerClick }) => {

  return (
    <div className={cn(className, 'UtilityMap')}>
      <GoogleMapReact 
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }} 
        center={{lat: 39.440334, lng: -84.362160}}
        zoom={5}>
      </GoogleMapReact>
    </div>
  )
}

export default UtilityMap
