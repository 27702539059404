import React, { useState, useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import './SideNavItem.scss'

const SideNavItem = ({ icon, text, path, selfNav, subMenuItems, className }) => {
  const location = useLocation()
  const Icon = icon
  const [open, setOpen] = useState(false)

  const sensor = Number(new URLSearchParams(location.search).get('sensor'))

  const handleClick = useCallback(
    event => {
      if (!selfNav && subMenuItems) {
        event.preventDefault()
        event.stopPropagation()
      }
      setOpen(!open)
    },
    [selfNav, open, subMenuItems]
  )

  return (
    <div className={"SideNavItem" + className}>
      <NavLink
        to={{ pathname: path, search: sensor ? `sensor=${sensor}` : null }}
        className="SideNavItem__link"
        activeClassName="selected"
        onClick={handleClick}>
        <Icon className="SideNavItem__icon" />
      </NavLink>     
    </div>
  )
}

export default SideNavItem
