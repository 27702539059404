import React from 'react'
import cn from 'classnames'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import SearchInput from 'components/SearchInput'
import UtilityMap from 'components/UtilityMap'
import { Tooltip } from 'antd'
import './OverviewMap.scss'

function OverviewMap() {
  const mapSection = `Showing the locations of the 
    pumps being assessed on a map. This 
    section contains a map showing the 
    locations related to the data being 
    displayed and the status, only applicable 
    for real time, of the data source.`
  return (
    <div className={cn('OverviewMap')}>
      <Form className="OverviewMap__header">
        <Form.Row className="align-items-center">
          <Form.Group as={Col} controlId="location" className={cn('d-flex align-items-center', 'mb-0 col-12 col-sm')}>
            <div className="title-div">
              <Tooltip title={mapSection}>
                <span className="title">PUMP MAP</span>
              </Tooltip>
            </div>
            <div className="search-input">
              <SearchInput type="text" placeholder="Search" autoComplete="off" className="OverviewMap__search" />
            </div>
          </Form.Group>
        </Form.Row>
      </Form>
      <UtilityMap className="OverviewMap__body" />
    </div>
  )
}

export default OverviewMap
