import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { history } from 'store'

import Login from 'routes/Login'
import SignUp from 'routes/SignUp'
import Overview from 'routes/Overview'

function Routes({ userRole }) {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route path="/" component={Overview} />
      </Switch>
    </Router>
  )
}

export default Routes
