import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SensorStatus from './routes/SensorStatus'

const Overview = () => (
  <Switch>
    <Route path={`/`} component={SensorStatus} />
  </Switch>
)

export default Overview
