import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import debounce from 'lodash/debounce'

import Navbar from 'react-bootstrap/Navbar'
import FooterUserActions from 'components/FooterUserActions'
import { logout } from 'store/modules/auth'

import './MobileFooter.scss'

function MobileFooter({ logout }) {
  const [shadow, setShadow] = useState(false)
  const handleScroll = debounce(() => {
    setShadow(window.innerHeight + window.scrollY < document.body.offsetHeight)
  }, 200)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <Navbar
      fixed="bottom"
      className={cn('MobileFooter', { 'MobileFooter--shadow': shadow }, 'justify-content-end', 'd-md-none')}>
      <FooterUserActions className="MobileFooter__icon" logout={logout} />
    </Navbar>
  )
}

const actions = {
  logout
}

export default connect(
  null,
  actions
)(MobileFooter)
