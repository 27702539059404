import React from 'react'
import './Logo.scss'
import { Link } from 'react-router-dom'
var VARUNA_LOGO = 'imgs/Varuna_Icon_logo.png'

const Logo = () => (
  <div className="SidebarLogo">
    <Link to="/overview">
      <img alt="IconLogo" src={VARUNA_LOGO}/>
    </Link>
  </div>
)

export default Logo
