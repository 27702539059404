import React from 'react'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { connect } from 'react-redux'
import IconData from 'icons/IconData'
import IconHouse from 'icons/IconHouse'
import IconQuestion from 'icons/IconQuestion'
import IconBell from 'icons/IconBell'
import SideNavItem from '../SideNavItem'
import { waterUtilitySelector } from 'store/modules/auth'
import './SideNavMenu.scss'

function SideNavMenu({ userRole, waterUtility }) {

  return (
    <div className="SideNavMenu">
      <SideNavItem text="OVERVIEW" path="/overview" icon={IconHouse} />
      <SideNavItem text="PUMP REPORT" path="/pump-reports" icon={IconData} />
      <SideNavItem text="SETTING" className="_setting" path="/setting" icon={IconBell} />
      <SideNavItem text="HELP" className="_help" path="/help" icon={IconQuestion} />
    </div>
  )
}
const selectors = createStructuredSelector({
  waterUtility: waterUtilitySelector
})

export default compose(connect(selectors))(SideNavMenu)
